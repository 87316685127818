<template>
  <v-container fluid class="page-container pa-0">
    <v-app-bar
      fixed
      app
      flat
      dark
      color="transparent"
      class="topbar"
      style="overflow-x:auto; white-space: nowrap;"
      :class="{
        'topbar--visible': showTopbarColor,
        'elevation-2': showTopbarColor,
      }"
      v-scroll="onScroll"
    >
      <v-btn
        plain
        @click="$vuetify.goTo('#cover', { duration: 1000, offset: 15 })"
      >
        <img
          class="btn-logo-img"
          alt="Brasão de Pernambuco"
          src="/img/brasao_64.png"
        />
      </v-btn>

      <v-app-bar-title
        v-text="app.alias"
        class="d-sm-none font-weight-bold"
      ></v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section1', { duration: 1000, offset: 15 })"
        >Sobre</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section2', { duration: 1000, offset: 15 })"
        >Acesso à plataforma</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section3', { duration: 1000, offset: 15 })"
        >Finalidades</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section4', { duration: 1000, offset: 15 })"
        >Detalhes</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section5', { duration: 1000, offset: 15 })"
        >Execução</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section6', { duration: 1000, offset: 15 })"
        >Documentos</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section7', { duration: 1000, offset: 15 })"
        >FAQ</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section8', { duration: 1000, offset: 15 })"
        >Transparência</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section9', { duration: 1000, offset: 15 })"
        >Informações de repasse</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section10', { duration: 1000, offset: 15 })"
        >Uso de Recurso</v-btn
      >

      <v-btn
        class="d-none d-sm-flex"
        plain
        tile
        @click="$vuetify.goTo('#section11', { duration: 1000, offset: 15 })"
        >Fale conosco</v-btn
      >
      <v-spacer></v-spacer>
    </v-app-bar>

    <cover id="cover" class="cover" src="/img/capa_01.png">
      <v-row no-gutters align="center" justify="center">
        <v-col class="text-center" cols="12">
          <v-img
            class="mb-10"
            contain
            max-height="300"
            src="/img/Marca-Programa-Investe-Escola-PE-01_Vertical_branco.png"
            alt="Logotipo do Programa investe escola pernambuco"
          ></v-img>

          <h1
            v-show="false"
            class="display-2 font-weight-black mb-12"
            v-text="app.name"
          ></h1>

          <div v-show="false" class="subheading font-weight-light mb-3">
            <span>Acesse de onde estiver a partir de █ █ / █ █ / █ █ █ █</span>
          </div>

          <v-btn
            v-show="false"
            class="pa-6 font-weight-regular"
            rounded
            color="success"
          >
            Quero Acessar
          </v-btn>
        </v-col>
      </v-row>
    </cover>

    <section id="section1" class="my-16">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="text-center order-1 order-sm-0">
            <div class="d-flex justify-center align-center full-parent">
              <vue-aos animation-class="animate__zoomInLeft animate__animated">
                <v-img
                  contain
                  max-height="300"
                  src="/img/Marca-Programa-Investe-Escola-PE-02_Horizontal.png"
                  alt="Logotipo do Programa investe escola pernambuco"
                ></v-img>
              </vue-aos>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pa-8 grey--text text--darken-1 order-0 order-sm-1"
          >
            <div class="d-flex justify-center align-center">
              <div>
                <vue-aos
                  animation-class="animate__zoomInRight animate__animated"
                >
                  <h2 class="text-h4 mb-8 black--text">Sobre</h2>
                </vue-aos>
                <vue-aos
                  animation-class="animate__zoomInRight animate__animated"
                >
                  <div>
                    <p class="text-justify">
                      O
                      <span class="black--text"
                        >Programa Investe Escola Pernambuco</span
                      >, criado em 2021, é uma política de descentralização de
                      recursos financeiros voltado às escolas da rede pública
                      estadual. Para serem contempladas com os recursos, as
                      escolas deverão, necessariamente, ter Unidades Executoras
                      Próprias (UEx) constituídas. O objetivo do Programa é
                      contribuir para a
                      <span class="black--text"
                        >manutenção e a melhoria das infraestruturas física e
                        pedagógica</span
                      >
                      das unidades escolares beneficiárias, tendo como foco
                      <span class="black--text"
                        >a elevação do desempenho escolar</span
                      >.
                    </p>
                  </div>
                </vue-aos>
              </div>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                href="https://www.siepe.educacao.pe.gov.br/"
                target="_blank"
                tile
                color="blue"
                class="white--text"
              >
                FAÇA O CADASTRO DA UNIDADE EXECUTORA
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section id="section2">
      <v-container>
        <vue-aos animation-class="animate__zoomInLeft animate__animated">
          <v-row no-gutters>
            <v-col class="text-center">
              <h2 class="text-h4">Acesso à Plataforma</h2>
            </v-col>
          </v-row>
        </vue-aos>
        <v-row align="center" justify="center">
          <v-btn @click="$router.push('/login')" tile color="success">
            <v-icon left> mdi-account-multiple </v-icon>
            FAZER LOGIN
          </v-btn>
        </v-row>
      </v-container>
    </section>

    <section id="section3" class="mt-16 py-10 grey lighten-3">
      <v-container>
        <vue-aos animation-class="animate__zoomInLeft animate__animated">
          <v-row no-gutters>
            <v-col class="text-center">
              <h2 class="text-h4">Finalidades</h2>
            </v-col>
          </v-row>
        </vue-aos>
        <v-row class="mt-16" no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col
              cols="10"
              sm="5"
              offset="1"
              class="pa-4 grey--text text--darken-1"
            >
              <div class="d-flex justify-start">
                <div class="pa-2">
                  <v-img
                    contain
                    max-height="100"
                    src="/img/Fin3.png"
                    alt="Icone implementação de projetos"
                  ></v-img>
                </div>
                <h4
                  class="text-justify text-center align-self-center text-h6 black--text mb-2"
                >
                  Implementação de projetos pedagógicos
                </h4>
              </div>
            </v-col>
          </vue-aos>

          <vue-aos animation-class="animate__zoomInRight animate__animated">
            <v-col
              cols="10"
              sm="5"
              offset="1"
              offset-sm="0"
              class="pa-4 grey--text text--darken-1"
            >
              <div class="d-flex justify-start">
                <div class="pa-2">
                  <v-img
                    contain
                    max-height="100"
                    src="/img/Fin6.png"
                    alt="Icone desenvolvimento de atividades educacionais"
                  ></v-img>
                </div>
                <h4
                  class="ml-3 text-justify text-center align-self-center text-h6 black--text mb-2"
                >
                  Desenvolvimento de atividades educacionais
                </h4>
              </div>
            </v-col>
          </vue-aos>

          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col
              cols="10"
              sm="5"
              offset="1"
              class="pa-4 grey--text text--darken-1"
            >
              <div class="d-flex justify-start">
                <div class="pa-2">
                  <v-img
                    contain
                    max-height="100"
                    max-width="100"
                    src="/img/Fin7.png"
                    alt="Icone avaliação de aprendizagem"
                  ></v-img>
                </div>
                <h4
                  class="ml-6 text-justify align-self-center text-h6 black--text mb-2"
                >
                  Avaliação de aprendizagem
                </h4>
              </div>
            </v-col>
          </vue-aos>

          <vue-aos animation-class="animate__zoomInRight animate__animated">
            <v-col
              cols="10"
              sm="5"
              offset="1"
              offset-sm="0"
              class="pa-4 grey--text text--darken-1"
            >
              <div class="d-flex justify-start">
                <div class="pa-2">
                  <v-img
                    contain
                    max-height="100"
                    src="/img/Fin5.png"
                    alt="Icone Manutenção, conservação e pequenos reparos"
                  ></v-img>
                </div>
                <h4
                  class="ml-3 text-justify align-self-center text-h6 black--text mb-2"
                >
                  Manutenção, conservação e pequenos reparos na infraestrutura
                  física da escola
                </h4>
              </div>
            </v-col>
          </vue-aos>

          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col
              cols="10"
              sm="5"
              offset="1"
              class="pa-4 grey--text text--darken-1"
            >
              <div class="d-flex justify-start">
                <div class="pa-2">
                  <v-img
                    contain
                    max-height="100"
                    src="/img/Fin2.png"
                    alt="Icone Material de consumo"
                  ></v-img>
                </div>
                <h4
                  class="ml-4 text-justify align-self-center text-h6 black--text mb-2"
                >
                  Material de consumo
                </h4>
              </div>
            </v-col>
          </vue-aos>

          <vue-aos animation-class="animate__zoomInRight animate__animated">
            <v-col
              cols="10"
              sm="5"
              offset="1"
              offset-sm="0"
              class="d-flex pa-4 grey--text text--darken-1"
            >
              <div class="d-flex justify-start">
                <div class="pa-2">
                  <v-img
                    contain
                    max-height="100"
                    src="/img/Fin4.png"
                    alt="Icone Material permanente"
                  ></v-img>
                </div>
                <h4
                  class="ml-2 text-justify align-self-center text-h6 black--text mb-2"
                >
                  Material permanente
                </h4>
              </div>
            </v-col>
          </vue-aos>

          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col
              cols="10"
              sm="5"
              offset="1"
              class="pa-4 grey--text text--darken-1"
            >
              <div class="d-flex justify-start">
                <div class="pa-2">
                  <v-img
                    contain
                    max-height="100"
                    src="/img/Fin1.png"
                    alt="Icone Despesas cartorárias"
                  ></v-img>
                </div>
                <h4
                  class="ml-3 text-justify align-self-center text-h6 black--text mb-2"
                >
                  Despesas cartorárias
                </h4>
              </div>
            </v-col>
          </vue-aos>
        </v-row>
      </v-container>
    </section>

    <section id="section4" class="pt-16 pb-10 grey lighten-5">
      <v-container>
        <vue-aos animation-class="animate__zoomInLeft animate__animated">
          <v-row no-gutters>
            <v-col class="text-center">
              <h2 class="text-h4">Informações Detalhadas</h2>
            </v-col>
          </v-row>
        </vue-aos>

        <v-row class="my-7" no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="pa-4 grey--text text--darken-1">
              <v-card elevation="1">
                <v-card-title
                  >Requisitos para recebimento dos recursos</v-card-title
                >
                <v-card-text>
                  <div class="text-justify">
                    <p>
                      Os repasses de recursos do
                      <span class="black--text">
                        Programa Investe Escola Pernambuco
                      </span>
                      dar-se-ão em parcela anual às Unidades Executoras (UEx)
                      cujas escolas realizarem, anualmente, a atualização
                      cadastral (ou o cadastro de novas entidades, no caso de
                      unidades escolares ainda sem UEx) e não possuírem
                      inadimplências com prestação de contas de recursos
                      recebidos em exercícios anteriores, de acordo com os
                      regramentos estabelecidos para o Programa.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </vue-aos>
        </v-row>

        <v-divider></v-divider>

        <v-row class="my-7" no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="pa-4 grey--text text--darken-1">
              <v-card elevation="1">
                <v-card-title>Participantes</v-card-title>
                <v-card-text>
                  <div class="text-justify">
                    <ul>
                      <li>
                        <strong>UEx:</strong>
                        responsável por empregar os recursos em favor das
                        escolas que representam, em conformidade com os
                        regramentos estabelecidos para o Programa.
                        <p
                          class="
                            mt-3
                            pa-3
                            grey
                            lighten-3
                            grey--text
                            text--darken-2
                            font-italic
                            rounded-lg
                          "
                        >
                          <strong>UEx – Unidade Executora Própria</strong>, é
                          uma organização da sociedade civil com personalidade
                          jurídica de direito privado, sem fins lucrativos,
                          constituída com a finalidade de representar uma
                          unidade escolar pública ou um consórcio de unidades
                          escolares públicas, integrada por membros da
                          comunidade escolar e comumente denominada de caixa
                          escolar, conselho escolar, associação de pais e
                          mestres, círculo de pais e mestres, dentre outras
                          denominações.
                        </p>
                      </li>

                      <li>
                        <strong>SEE:</strong>
                        Secretaria de Educação e Esportes de Pernambuco -
                        responsável pelas políticas educacionais das escolas
                        públicas estaduais de Pernambuco, com o objetivo de
                        alcançar a melhoria e garantir uma educação de qualidade
                        a todos;
                      </li>

                      <li>
                        <strong>GRE:</strong>
                        Gerência Regional de Educação – responsável por
                        acompanhar/monitorar o emprego dos recursos das unidades
                        escolares a ela jurisdicionadas, bem como validar o PAF
                        (Plano de Aplicação Financeira) e a prestação de contas.
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </vue-aos>
        </v-row>

        <v-divider></v-divider>

        <v-row class="my-7" no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="pa-4 grey--text text--darken-1">
              <v-card elevation="1">
                <v-card-title
                  >Como calcular o valor do repasse financeiro</v-card-title
                >
                <v-card-text>
                  <div class="text-justify">
                    <p>O cálculo é feito da seguinte forma:</p>
                    <p
                      class="
                        pa-3
                        grey
                        lighten-3
                        grey--text
                        text--darken-2
                        font-italic
                        rounded-lg
                      "
                    >
                      VR = VF*NE
                    </p>
                    <p>
                      <small>
                        Legenda
                        <ul>
                          <li><strong>VR:</strong> Valor do repasse</li>
                          <li><strong>VF:</strong> Valor fixo</li>
                          <li><strong>NE:</strong> Número de estudantes</li>
                          <li><strong>60% Custeio</strong></li>
                          <li><strong>40% Capital</strong></li>
                        </ul>
                      </small>
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </vue-aos>
        </v-row>

        <v-divider></v-divider>

        <v-row class="mt-7" no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="pa-4 grey--text text--darken-1">
              <v-card elevation="1">
                <v-card-title>Prestação de Contas</v-card-title>
                <v-card-text>
                  <div>
                    <p class="text-justify">
                      A prestação de contas é a comprovação da destinação dada
                      aos recursos financeiros que foram recebidos naquele
                      exercício. A UEx terá o prazo determinado em Portaria para
                      apresentar a prestação de contas à GRE/SEE.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </vue-aos>
        </v-row>
      </v-container>
    </section>

    <section id="section4">
      <v-row no-gutters>
        <v-col class="text-center px-0">
          <cover :height="300" src="/img/capa_02.png">
            <v-row class="d-flex justify-space-between align-center">
              <vue-aos animation-class="animate__zoomInLeft animate__animated">
                <v-col>
                  <div class="text-h4 font-weight-bold">1.059</div>
                  <span class="font-weight-light">Escolas</span>
                </v-col>
              </vue-aos>
              <vue-aos animation-class="animate__zoomInDown animate__animated">
                <v-col>
                  <div class="text-h4 font-weight-bold">575</div>
                  <span class="font-weight-light"
                    >Escolas em tempo integral</span
                  >
                </v-col>
              </vue-aos>
              <vue-aos animation-class="animate__zoomInRight animate__animated">
                <v-col>
                  <div class="text-h4 font-weight-bold">533.000</div>
                  <span class="font-weight-light">Estudantes</span>
                </v-col>
              </vue-aos>
            </v-row>
          </cover>
        </v-col>
      </v-row>
    </section>

    <section id="section5" class="my-16">
      <v-container>
        <vue-aos animation-class="animate__zoomInLeft animate__animated">
          <v-row no-gutters>
            <v-col class="text-center">
              <h2 class="text-h4">Execução</h2>
            </v-col>
          </v-row>
        </vue-aos>

        <v-row no-gutters class="mt-15">
          <v-col>
            <v-timeline>
              <v-timeline-item small>
                <template v-slot:opposite>
                  <vue-aos
                    animation-class="animate__zoomInLeft animate__animated"
                  >
                    <div>
                      <span class="font-weight-bold">Passo 1</span>
                    </div>
                  </vue-aos>
                </template>
                <vue-aos
                  animation-class="animate__zoomInRight animate__animated"
                >
                  <div class="py-4">
                    <h2 class="headline font-weight-light mb-4">
                      Planejamento Participativo
                    </h2>
                    <p class="text-justify">
                      Um dos objetivos do Programa Investe Escola Pernambuco é o
                      controle social. Sendo assim, a comunidade escolar deve
                      ser convocada a participar de todo o processo.
                    </p>
                  </div>
                </vue-aos>
              </v-timeline-item>

              <v-timeline-item small>
                <template v-slot:opposite>
                  <vue-aos
                    animation-class="animate__zoomInRight animate__animated"
                  >
                    <div>
                      <span class="font-weight-bold">Passo 2</span>
                    </div>
                  </vue-aos>
                </template>
                <vue-aos
                  animation-class="animate__zoomInLeft animate__animated"
                >
                  <div class="py-4">
                    <h2 class="headline font-weight-light mb-4">
                      Pesquisa de Preços
                    </h2>
                    <p class="text-justify">
                      Após decidir democrática e conjuntamente a melhor forma de
                      utilização dos recursos, é necessário buscar fornecedores
                      e/ou prestadores de serviços para o fornecimento de bens e
                      materiais e/ou execução dos serviços. Devem ser
                      realizadas, no mínimo, três Pesquisas de Preços – de
                      preferência, no comércio local.
                    </p>
                  </div>
                </vue-aos>
              </v-timeline-item>

              <v-timeline-item small>
                <template v-slot:opposite>
                  <vue-aos
                    animation-class="animate__zoomInLeft animate__animated"
                  >
                    <div>
                      <span class="font-weight-bold">Passo 3</span>
                    </div>
                  </vue-aos>
                </template>
                <vue-aos
                  animation-class="animate__zoomInRight animate__animated"
                >
                  <div class="py-4">
                    <h2 class="headline font-weight-light mb-4">
                      Aquisição ou Contratação
                    </h2>
                    <p class="text-justify">
                      Os documentos comprobatórios das despesas efetivadas
                      (notas fiscais, faturas, recibos etc.) devem compor a
                      prestação de contas e ser emitidos em nome da UEx, além de
                      conter informações que identifiquem a origem dos recursos,
                      o atesto do recebimento do material ou bem fornecido e/ou
                      do serviço prestado à escola, o registro de quitação das
                      despesas efetivadas, dentre outras.
                    </p>
                  </div>
                </vue-aos>
              </v-timeline-item>

              <v-timeline-item small>
                <template v-slot:opposite>
                  <vue-aos
                    animation-class="animate__zoomInRight animate__animated"
                  >
                    <div>
                      <span class="font-weight-bold">Passo 4</span>
                    </div>
                  </vue-aos>
                </template>
                <vue-aos
                  animation-class="animate__zoomInLeft animate__animated"
                >
                  <div class="py-4">
                    <h2 class="headline font-weight-light mb-4">
                      Doação de Bens Permanentes
                    </h2>
                    <p class="text-justify">
                      Todo bem permanente adquirido ou produzido com os recursos
                      do Programa Investe Escola Pernambuco deve ser tombado e
                      incorporado ao patrimônio da Entidade Executora a cuja
                      rede de ensino a escola esteja vinculada.
                    </p>
                  </div>
                </vue-aos>
              </v-timeline-item>

              <v-timeline-item small>
                <template v-slot:opposite>
                  <vue-aos
                    animation-class="animate__zoomInLeft animate__animated"
                  >
                    <div>
                      <span class="font-weight-bold">Passo 5</span>
                    </div>
                  </vue-aos>
                </template>
                <vue-aos
                  animation-class="animate__zoomInRight animate__animated"
                >
                  <div class="py-4">
                    <h2 class="headline font-weight-light mb-4">
                      Guarda dos Documentos
                    </h2>
                    <p class="text-justify">
                      Os originais das prestações de contas deverão ser
                      arquivados na sede da Unidade Executora pelo prazo de 10
                      (dez) anos, contado da data do julgamento da prestação de
                      contas anual pela GRE/SEE referente ao exercício do
                      repasse.
                    </p>
                  </div>
                </vue-aos>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section id="section6" class="my-10 py-10 grey lighten-3">
      <v-container>
        <v-row no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="text-center">
              <h2 class="text-h4">Documentos</h2>
            </v-col>
          </vue-aos>
        </v-row>

        <v-row class="mt-4 justify-center align-content-center">
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <div>
              Para maiores informações sobre o
              <strong class="light-blue--text text--darken-4"
                >Programa Investe Escola Pernambuco</strong
              >, processo de cadastros, onde e como pode ser o usado o recurso,
              acesse os documentos abaixo:
            </div>
          </vue-aos>
        </v-row>

        <v-row>
          <v-col>
            <lading-page-documentos
              :items="documentos"
            ></lading-page-documentos>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section id="section7" class="my-10">
      <v-container>
        <v-row no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="text-center">
              <h2 class="text-h4">Perguntas Frequentes</h2>
            </v-col>
          </vue-aos>
        </v-row>

        <v-row>
          <v-col>
            <lading-page-faq :items="faq"></lading-page-faq>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section id="section8" class="my-10 py-10 lighten-3">
      <v-container>
        <v-row class="mt-4 justify-center align-content-center">
          <v-col cols="10" md="6" lg="4">
            <vue-aos animation-class="animate__zoomInLeft animate__animated">
              <div class="text-h4 text-center">
                <v-col>
                  <strong
                    class="font-weight-bold light-blue--text text--darken-4"
                    >Transparência</strong
                  >
                  no
                </v-col>
                envio de recursos
              </div>
            </vue-aos>
          </v-col>
          <v-col cols="10" md="6" lg="4">
            <vue-aos animation-class="animate__zoomInRight animate__animated">
              <div class="text-justify">
                Qualquer pessoa poderá verificar os repasses dos recursos do
                Programa Investe Escola Pernambuco. Com o objetivo de garantir o
                acesso às informações, dando a todos o direito de receber dos
                órgãos públicos informações de seu interesse, disponibilizamos
                dados dos repasses por escola, Unidade Executora, município,
                Gerência Regional de Educação e ano.
              </div>
            </vue-aos>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section id="section9" class="my-10 py-5 lighten-3">
      <v-container>
        <v-row no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="text-center">
              <h2 class="text-h4">Informações de Repasse</h2>
            </v-col>
          </vue-aos>
        </v-row>
        <v-row class="mt-4 justify-center align-content-center">
          <v-col cols="10" md="6" lg="4">
            <vue-aos animation-class="animate__zoomInLeft animate__animated">
              <div>
                <v-col>
                  DADOS DE REPASSE
                  <div class="text-h4">
                    Consulta de dados
                    <strong
                      class="font-weight-bold light-blue--text text--darken-4"
                      ><br />por escola</strong
                    >
                  </div>
                  <p class="text-justify">
                    Selecione uma ou mais opções no filtro de busca e confira os
                    dados de repasse das verbas do PIEPE na sua escola.
                  </p>
                </v-col>
              </div>
            </vue-aos>
          </v-col>
          <v-col cols="10" md="6" lg="4">
            <lading-page-info-repasse
              :gres="gres"
              :diretorias="diretorias"
              :municipios="municipios"
              :escolas="escolas"
              :anos="anos"
            ></lading-page-info-repasse>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section
      v-if="isGeneratingReports"
      id="relatorio"
      class="my-5 py-5 lighten-3"
    >
      <lading-page-relatorio-repasse></lading-page-relatorio-repasse>
    </section>

    <section id="section10" class="blue grey lighten-3">
      <v-container>
        <v-row no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="text-center">
              <vue-aos animation-class="animate__zoomInLeft animate__animated">
                <div class="text-h4">
                  <v-col>
                    <div class="justify-center">
                      A
                      <strong
                        class="font-weight-bold light-blue--text text--darken-4"
                        >aplicação dos recursos</strong
                      >
                      do PIEPE
                    </div>
                    se dá por meio das seguintes ações:
                  </v-col>
                </div>
              </vue-aos>
            </v-col>
          </vue-aos>
        </v-row>
        <lading-page-recursos></lading-page-recursos>
      </v-container>
    </section>

    <section id="section11" class="my-10 py-10 lighten-3">
      <v-container>
        <v-row no-gutters>
          <vue-aos animation-class="animate__zoomInLeft animate__animated">
            <v-col class="text-center">
              <h2 class="text-h4">Fale conosco</h2>
            </v-col>
          </vue-aos>
        </v-row>
        <v-row class="mt-4 justify-center align-content-center">
          <vue-aos animation-class="animate__zoomInRight animate__animated">
            <h4 class="text-center info-text pt-2 pb-8">
              Para maiores informações, acessar o nosso portal
              <a
                target="_blank"
                href="https://www.cesu.pe.gov.br/citsmart/pages/login/login.load"
              >
                clicando aqui
              </a>
            </h4>
          </vue-aos>
        </v-row>
      </v-container>
    </section>

    <section id="section8" class="blue lighten-3">
      <v-container>
        <v-row class="py-5">
          <v-col class="d-none d-md-flex font-weight-bold"
            >Siga-nos nas nossas redes sociais</v-col
          >
          <v-col
            class="
              d-flex
              justify-space-around justify-md-space-between
              text-center text-md-right
            "
          >
            <a
              rel="noreferrer noopener"
              class="
                font-weight-medium
                light-blue--text
                text--darken-4 text-decoration-none
              "
              target="_blank"
              href="https://www.instagram.com/educacaopeoficial/"
            >
              <v-icon>mdi-instagram</v-icon>
            </a>
            <a
              rel="noreferrer noopener"
              class="
                font-weight-medium
                light-blue--text
                text--darken-4 text-decoration-none
              "
              target="_blank"
              href="https://www.facebook.com/secretariadeeducacaodepernambuco"
            >
              <v-icon>mdi-facebook</v-icon>
            </a>
            <a
              rel="noreferrer noopener"
              class="
                font-weight-medium
                light-blue--text
                text--darken-4 text-decoration-none
              "
              target="_blank"
              href="https://www.youtube.com/user/EducacaoPE"
            >
              <v-icon>mdi-youtube</v-icon>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <lading-page-footer></lading-page-footer>
  </v-container>
</template>

<script>
import VueAos from "vue-aos";
import configurations from "commons/configurations";
import pageLoadingMixin from "mixins/page-loading.mixin";
import notificationMixin from "mixins/notification.mixin";

import Cover from "views/pre-login/lading-page/lading-page-cover";
import LadingPageFaq from "views/pre-login/lading-page/lading-page-faq";
import LadingPageInfoRepasse from "views/pre-login/lading-page/lading-page-info-repasse";
import LadingPageFooter from "views/pre-login/lading-page/lading-page-footer";
import LadingPageRecursos from "views/pre-login/lading-page/lading-page-recursos";
import LadingPageDocumentos from "views/pre-login/lading-page/lading-page-documentos";
import LadingPageRelatorioRepasse from "views/pre-login/lading-page/lading-page-relatorio-repasse.vue";

import {
  PRE_LOGIN_LAYOUT_NAMESPACE,
  IS_GENERATING_REPORTS,
} from "store/modules/pre-login-layout";

export default {
  name: "LadingPage",
  components: {
    VueAos,
    Cover,
    LadingPageFaq,
    LadingPageFooter,
    LadingPageInfoRepasse,
    LadingPageRecursos,
    LadingPageDocumentos,
    LadingPageRelatorioRepasse,
  },
  mixins: [pageLoadingMixin, notificationMixin],
  data() {
    return {
      app: {
        name: configurations.app.name,
        alias: configurations.app.alias,
      },
      faq: [],
      documentos: [],
      gres: [],
      anos: [],
      diretorias: [],
      municipios: [],
      escolas: [],
      showTopbarColor: false,
    };
  },
  beforeMount() {
    this.hidePageLoading();
    this.fetchFaq();
    this.fetchObterFiltroDadosRepasse();
    this.fetchDocumentos();
  },
  computed: {
    isGeneratingReports() {
      return this.$store.getters[
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${IS_GENERATING_REPORTS}`
      ];
    },
  },
  methods: {
    onScroll() {
      const offset = 150;
      if (window.pageYOffset > offset) {
        this.showTopbarColor = true;
      } else {
        this.showTopbarColor = false;
      }
    },
    fetchFaq() {
      this.$api()
        .get(configurations.api.home.faq)
        .then(({ data }) => {
          this.faq = data.faq.map((item) => {
            return {
              id: item.id,
              title: item.pergunta,
              description: item.resposta,
              tokens: item.palavrasChave,
            };
          });
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning(
              "Não conseguimos consultar as perguntas e respostas do FAQ."
            );
          }
        });
    },
    fetchObterFiltroDadosRepasse() {
      this.$api()
        .get(configurations.api.home.obterFiltroDadosRepasse)
        .then(({ data }) => {
          this.gres = data.dados.gres;
          this.diretorias = data.dados.diretorias; //.map((item) => item.nome);
          this.escolas = data.dados.escolas; //.map((item) => item.nome);
          this.municipios = data.dados.municipios; //.map((item) => item.nome);
          this.anos = data.dados.anos;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning(
              "Não conseguimos consultar as informações de escolas, municípios e UEx."
            );
          }
        });
    },
    fetchDocumentos() {
      this.$api()
        .get(configurations.api.home.getListaDocumentosPublicos)
        .then(({ data }) => {
          this.documentos = data.documento.map((item) => {
            return {
              id: item.id,
              title: item.descricao,
              description: item.descricao,
              arquivo: item.documento,
              tokens: item.descricao,
            };
          });
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos consultar os Documentos.");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$negativeTopbarHeight: -64px;

.topbar {
  transition: all 0.75s;
  height: 64px !important;
}

.topbar--visible {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}

.page-container {
  .btn-logo-img {
    max-height: 36px;
  }
  .cover {
    margin-top: $negativeTopbarHeight;
  }
}

.divImg {
  width: 100px;
}
</style>
