<template>
  <div class="lading-page-cover">
    <v-parallax
      :src="src"
      :height="parallaxHeight"
      :class="{ 'dark': dark, 'default-height': !hasCustomHeight }"
    >
      <slot></slot>
    </v-parallax>
  </div>
</template>

<script>
export default {
  name: "LandingPageCover",
  props: {
    height: {
      type: Number,
    },
    src: {
      type: String,
      default: "/img/bg-08.jpg",
    },
    dark: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      windowHeight: undefined,
    };
  },
  mounted() {
    if (isNaN(parseInt(this.height))) {
      window.addEventListener("resize", this.onResizeWindow);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.aoRedimensionarJanelav);
  },
  computed: {
    parallaxHeight() {
      return this.hasCustomHeight
        ? this.height
        : this.windowHeight || window.innerHeight;
    },
    hasCustomHeight() {
      return !isNaN(parseInt(this.height)) && parseInt(this.height) !== 0;
    }
  },
  methods: {
    onResizeWindow() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-parallax {
  max-width: 100vw;
  
  &.default-height {
    min-height: calc(100vh + 12px);
  }

  &.dark {
    .v-parallax__image-container {
      filter: brightness(40%);
    }
  }
}
</style>
