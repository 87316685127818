<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="page--login d-flex justify-center align-center">
          <div class="form-container">
            <v-card class="pa-10" color="rgba(255, 255, 255, .8)">
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col class="text-center">
                    <img
                      src="/img/brasao_64.png"
                      alt="Brasão do state de Pernambuco"
                    />
                    <br />
                    <h1
                      class="subtitle-1 blue--text text--darken-4"
                      v-text="app.name"
                    ></h1>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.username"
                      solo
                      hide-details="auto"
                      placeholder="Nome de usuário"
                      :rules="regrasDeNomeDeUsuario"
                      @keyup.enter="enter"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.password"
                      type="password"
                      solo
                      placeholder="Senha"
                      hide-details="auto"
                      :rules="passwordRules"
                      @keyup.enter="enter"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      block
                      color="success"
                      :loading="loading"
                      @click.prevent="enter"
                    >
                      Entrar
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0 mt-3 text-center">
                    <small>
                      <a @click.prevent="onClickEsqueciSenha()"
                        >Esqueci minha senha</a
                      >
                    </small>
                  </v-col>
                </v-row>

              </v-form>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import {
  AUTH_NAMESPACE,
  LOGIN,
  UPDATE_MENU,
  UPDATE_ABILITIES,
} from "store/modules/auth";
import { CLEAR } from "store";
import configurations from "commons/configurations";
import pageLoadingMixin from "mixins/page-loading.mixin";
import notificationMixin from "mixins/notification.mixin";
import Validations from "services/validations/validations.js";
import { ConvertPermissionsIntoAbilities } from "services/abilities/ability";

const { mapActions: authActions } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "Login",
  mixins: [pageLoadingMixin, notificationMixin],
  components: {},
  data: () => ({
    valid: false,
    loading: false,
    app: {
      name: configurations.app.alias || "Acessar o Sistema",
    },
    user: {
      username: undefined,
      password: undefined,
    },
    redirectUrl: "",
  }),
  computed: {
    regrasDeNomeDeUsuario() {
      return [(value) => Validations.required(value, "Nome é obrigatório")];
    },
    passwordRules() {
      return [(value) => Validations.required(value, "Senha é obrigatória")];
    },
  },
  beforeMount() {
    this.hidePageLoading();
    this.$store.dispatch(CLEAR);
  },
  methods: {
    ...authActions([LOGIN, UPDATE_MENU, UPDATE_ABILITIES]),
    enter() {
      this.$refs.form.validate();

      if (!this.valid) {
        return;
      }

      this.loading = true;

      this.$api()
        .post(configurations.api.auth.authenticateUri, {
          nomeDeUsuario: this.user.username,
          senha: this.user.password,
        })
        .then(({ data }) => {
          if (data.sucesso) {
            return this[LOGIN](data);
          } else {
            this.loading = false;
            this.notifyError(data.mensagem);
          }
        })
        .then(() => {
          return this.$api().get(
            configurations.api.auth.getUserPermissionsUri,
            {
              params: { nomeDeUsuario: this.user.username },
            }
          );
        })
        .then(({ data }) => {
          const abilities = ConvertPermissionsIntoAbilities(data.permissoes);
          this[UPDATE_ABILITIES](abilities);
          return this.updateAbility(abilities);
        })
        .then(() => {
          return this.$api().get(configurations.api.auth.getMenuUri, {
            params: { nomeDeUsuario: this.user.username },
          });
        })
        .then(({ data }) => {
          return this[UPDATE_MENU](this.getTransformedMenu(data.itens));
        })
        .then(() => {
          this.$router.push({ path: this.redirectUrl || "/admin" });
        })
        .catch(({ response }) => {
          this.loading = false;

          this.$store.dispatch(CLEAR);

          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError(
              "Não conseguimos nos comunicar com o servidor da aplicação."
            );
          }
        });
    },
    updateAbility(permissions) {
      this.$ability.update(permissions);
    },
    getTransformedMenu(menuItems) {
      if (menuItems) {
        let menu = [];
        for (let item of menuItems) {
          if (!item.nome || item.nome.toString().trim() === "") {
            continue;
          }
          menu.push(this.deepTransformation(item));
        }
        if (menu && menu.length > 0) this.redirectUrl = menu[0].url;
        return menu;
      }

      return [];
    },
    deepTransformation(menuItem) {
      if (menuItem) {
        return {
          name: menuItem.nome,
          url: menuItem.endereco,
          icon: menuItem.icone,
          items: [],
        };
      }

      return undefined;
    },
    onClickEsqueciSenha(){
      this.$router.push({ path: "/EsqueciASenha" });
    }
  },
};
</script>

<style lang="scss" scoped>
.page--login {
  min-height: calc(100vh - 24px) !important;
  .form-container {
    form {
      min-width: 250px;
    }
  }
}
</style>
