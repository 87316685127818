<template>
  <div class="documentos">
    <v-text-field
      v-model="textToSearch"
      solo
      rounded
      clearable
      :loading="isSearching"
      placeholder="Pesquise o Documento"
      prepend-inner-icon="mdi-magnify"
      @click:clear="clear"
      @input="search"
      autocomplete="off"
    ></v-text-field>

    <v-expansion-panels focusable v-model="panel">
      <v-expansion-panel v-for="(item, i) in visiblePage" :key="i">
        <v-expansion-panel-header>
          <strong
            v-text="item.title"
            class="text--darken-2 grey--text"
          ></strong>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <download
            :file="{ path: item.arquivo, fileName: item.title }"
            label="Clique aqui para fazer o download"
            :uriDownload="urlDownload"
          ></download>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div
      v-if="searchedItems.length && paginationLength > 1"
      class="text-center mt-5"
    >
      <v-pagination
        v-model="pagination.page"
        :length="paginationLength"
        @input="panel = []"
        circle
      ></v-pagination>
    </div>

    <div
      class="text-center text--darken-1 grey--text font-weight-light"
      v-if="!searchedItems.length"
    >
      Infelizmente não encontramos nenhum documento...
      <v-icon>mdi-emoticon-sad-outline</v-icon>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import Lunr from "lunr";
import lunrStemmerSupport from "lunr-languages/lunr.stemmer.support";
import lunrLangPt from "lunr-languages/lunr.pt";
import configurations from "~/commons/configurations";
import download from "~/components/download.vue";

export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
      validator(value) {
        if (!Array.isArray(value)) {
          return false;
        }

        if (value.length > 0) {
          return !value.some((item) => {
            return (
              !item.hasOwnProperty("id") ||
              !item.hasOwnProperty("title") ||
              !item.hasOwnProperty("description") ||
              !item.hasOwnProperty("arquivo")
            );
          });
        }

        return true;
      },
    },
  },
  components: {
    download,
  },
  data: () => ({
    textToSearch: undefined,
    isSearching: false,
    interval: undefined,
    searchEngine: undefined,
    searchEngine1: undefined,
    panel: [],
    searchedItems: [],
    pagination: {
      page: 1,
      size: 5,
    },
  }),
  computed: {
    visiblePage() {
      return this.searchedItems.slice(
        (this.pagination.page - 1) * this.pagination.size,
        this.pagination.page * this.pagination.size
      );
    },
    paginationLength() {
      return Math.ceil(this.searchedItems.length / this.pagination.size);
    },
    urlDownload() {
      return configurations.api.home.download;
    },
  },
  watch: {
    items() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    getArquivo(documento) {
      var retorno = configurations.arquivos.paginaInicial + documento;
      return retorno;
    },
    init() {
      this.prepareSearchEngine();
      this.searchedItems = this.items;
    },
    clear() {
      this.searchedItems = this.items;
      this.panel = [];
    },
    getNormalizedText(text) {
      return (text || "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },
    prepareSearchEngine() {
      if (typeof Lunr.pt === "undefined") {
        lunrStemmerSupport(Lunr);
        lunrLangPt(Lunr);
      }

      const self = this;
      const engine = Lunr(function() {
        this.use(Lunr.pt);
        this.ref("id");
        this.field("title");
        this.field("description");
        this.field("tokens");
        this.field("arquivo");
        for (let documento of self.items) {
          this.add({
            id: documento.id,
            title: self.getNormalizedText(documento.title),
            description: self.getNormalizedText(documento.description),
            arquivo: self.getNormalizedText(documento.arquivo),
            tokens: self.getNormalizedText(documento.description || []),
          });
        }
      });

      this.searchEngine = Object.freeze(engine);
    },
    search() {
      const minCharacters = 3;
      const searchDelay = 500;
      const searchedText = this.getNormalizedText(this.textToSearch || "");

      this.isSearching = false;

      clearTimeout(this.interval);

      if (searchedText.length >= minCharacters) {
        this.isSearching = true;
        this.interval = setTimeout(() => {
          const searchedIds = (searchedText.length >= minCharacters
            ? this.searchEngine.search(searchedText) || []
            : []
          ).map((s) => parseInt(s.ref));
          this.searchedItems = this.items.filter(
            (item) => searchedIds.indexOf(item.id) >= 0
          );
          this.panel = [];
          this.isSearching = false;
        }, searchDelay);
      } else {
        this.searchedItems = this.items;
      }
    },
  },
};
</script>
