<template>
  <v-app id="app-wrapper">
    <img
      v-if="backgroundImageIsVisible"
      alt="Imagem de fundo"
      id="background-image"
      :src="backgroundImageSrc"
    />

    <v-app-bar
      id="topbar"
      class="asdsadsadsa"
      color="transparent"
      :src="backgroundImageSrc"
      dense
      fixed
    >
      <v-app-bar-nav-icon
        v-if="!permanentSidebar"
        @click.stop="onToggleClicked"
      ></v-app-bar-nav-icon>
      <v-toolbar-title v-if="permanentSidebar" class="d-flex align-center mr-3">
        <router-link
          to="/admin"
          class="d-flex align-centerd-flex align-center full-parent-height"
        >
          <img
            class="app-icon full-parent-height"
            alt="Brasão de Pernambuco"
            src="/img/brasao_64.png"
          />
        </router-link>
      </v-toolbar-title>

      <v-toolbar-title
        v-if="!permanentSidebar"
        class="pl-0 d-flex align-center grey--text text--darken-1"
      >
        <small
          ><strong v-text="app.alias"></strong> -
          <strong v-text="currentPageTile"></strong
        ></small>
      </v-toolbar-title>

      <template v-if="permanentSidebar">
        <v-menu v-for="(item, i) in menu" :key="i" open-on-hover offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              color="transparent"
              height="100%"
              small
              v-bind="attrs"
              v-on="on"
              v-text="item.name"
            ></v-btn>
          </template>
          <v-list dense flat>
            <v-list-item-group color="primary">
              <v-list-item
                :to="menu.url"
                v-for="(menu, menuIndex) in item.items"
                :key="menuIndex"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="menu.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="transparent"
            class="text-right"
            elevation="0"
            height="100%"
            v-bind="attrs"
            v-on="on"
            @click="logout"
          >
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Sair</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      v-if="!permanentSidebar || currentPageHasMenu"
      id="sidebar"
      app
      floating
      fixed
      clipped
      :permanent="permanentSidebar"
      :color="sidebarColor"
      :src="sidebarBackgroundImage"
      v-model="drawer"
      @input="onDrawerToggled"
    >
      <!-- xl and sm -->
      <template v-if="!permanentSidebar">
        <v-list shaped class="mt-11">
          <v-list-group v-for="(item, index) in menu" :key="index" no-action>
            <template v-slot:activator>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </template>

            <v-list-item
              link
              :to="child.url"
              v-for="(child, i) in item.items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.name"> </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </template>

      <!-- md and up -->
      <template v-else>
        <v-list shaped class="mt-14">
          <v-subheader v-text="currentPageMenu.name"></v-subheader>
          <v-list-item
            link
            :to="item.url"
            v-for="(item, i) in currentPageMenu.items"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"> </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main id="main" class="mt-12 pl-0">
      <v-container fluid>
        <v-row v-if="deferredPrompt">
          <v-col>
            <v-banner dark color="info">
              <div class="d-flex align-center font-weight-light">
                <v-icon color="white" class="mr-4"
                  >mdi-information-outline</v-icon
                >
                <span
                  >Obtenha nosso aplicativo gratuito. Não vai ocupar espaço no
                  seu telefone e também funciona offline!</span
                >
              </div>
              <template v-slot:actions>
                <v-btn small text class="ml-5" @click="dismissPwa"
                  >Ignorar</v-btn
                >
                <v-btn small text @click="installPwa">Instalar</v-btn>
              </template>
            </v-banner>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <vue-page-transition name="fade-in-right">
              <router-view
                class="page"
                @onDrawerToggled="onDrawerToggled"
                :drawer="drawer"
              />
            </vue-page-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {
  PRE_LOGIN_LAYOUT_NAMESPACE,
  BACKGROUND_IMAGE,
  BACKGROUND_IMAGE_VISIBILITY,
} from "store/modules/pre-login-layout";
import pageLoadingMixin from "mixins/page-loading.mixin";
import { AUTH_NAMESPACE, MENU } from "store/modules/auth";
import configurations from "commons/configurations";

const pwaDeferrerdPromptKey = "pwa-add-to-home-screen";

export default {
  name: "PostLoginLayout",
  mixins: [pageLoadingMixin],
  components: {},
  beforeMount() {
    this.hidePageLoading();
  },
  data: () => ({
    app: {
      name: configurations.app.name,
      alias: configurations.app.alias,
    },
    deferredPrompt: null,
    drawer: null,
  }),
  computed: {
    backgroundImageSrc() {
      return this.$store.getters[
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${BACKGROUND_IMAGE}`
      ];
    },
    backgroundImageIsVisible() {
      return this.$store.getters[
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${BACKGROUND_IMAGE_VISIBILITY}`
      ];
    },
    menu() {
      return [
        {
          icon: "mdi-home",
          name: "Investe Escola",
          items: this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`],
        },
      ];
    },
    currentPageMenu() {
      return this.menu.find(
        (m) =>
          (m.url &&
            m.url.toLocaleLowerCase() ===
              this.$route.fullPath.toLocaleLowerCase()) ||
          (m.items &&
            m.items.find(
              (child) =>
                child.url &&
                child.url.toLocaleLowerCase() ===
                  this.$route.fullPath.toLocaleLowerCase()
            ))
      );
    },
    currentPageHasMenu() {
      if (
        this.currentPageMenu &&
        this.currentPageMenu.items &&
        this.currentPageMenu.items.length
      ) {
        return true;
      }

      return false;
    },
    permanentSidebar() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    sidebarBackgroundImage() {
      return !this.permanentSidebar ? this.backgroundImageSrc : null;
    },
    sidebarColor() {
      return this.permanentSidebar ? "transparent" : "white";
    },
    currentPageTile() {
      return this.$route.meta.title;
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();

      // Stash the event so it can be triggered later.
      const pwaPrompCache = localStorage.getItem(pwaDeferrerdPromptKey);
      if (pwaPrompCache === "" || pwaPrompCache === null) {
        this.deferredPrompt = e;
      }
    });

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async onDrawerToggled(state) {
      this.drawer = state;
    },
    async onToggleClicked() {
      this.drawer = !this.drawer;
    },
    async logout() {
      this.$router.push({ path: "/login" });
    },
    async dismissPwa() {
      localStorage.setItem(pwaDeferrerdPromptKey, null);
      this.deferredPrompt = null;
    },
    async installPwa() {
      this.deferredPrompt.prompt();
    },
  },
};
</script>

<style lang="scss" scoped>
#app-wrapper {
  background: #f5f5f5;

  #background-image {
    position: fixed;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
::v-deep #topbar {
  &::before {
    content: "";
    display: none;
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(47, 143, 204);
    background: linear-gradient(
      90deg,
      rgba(47, 143, 204, 1) 0%,
      rgba(47, 143, 204, 1) 42%,
      rgba(71, 181, 84, 1) 42%,
      rgba(71, 181, 84, 1) 57%,
      rgba(252, 185, 44, 1) 57%,
      rgba(252, 185, 44, 1) 71%,
      rgba(234, 54, 57, 1) 71%,
      rgba(234, 54, 57, 1) 100%
    );
  }
  .v-image__image.v-image__image--cover {
    background-position: 0 0 !important;
  }
  .app-icon {
    max-height: 32px;
  }
  .v-toolbar__content {
    padding-right: 0;
  }
}

#sidebar {
  z-index: 1;
}

#main {
  .page {
    min-height: 75vh;
  }
}
</style>
