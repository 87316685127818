<template>
  <v-container>
    <v-row>
      <vue-aos animation-class="animate__zoomInLeft animate__animated">
        <v-col align="center" justify="center">
          <div class="text-h5 justify-center">
            <strong class="light-blue--text text--darken-4"
              >VOCÊ PESQUISOU:</strong
            >
            <span v-if="!nomeGreValido"> GRE: {{ reportFilter.greNome }} </span>
            <span v-if="!nomeUnidadeExecutoraValido">
              - UEx: {{ reportFilter.unidadeExecutora.nome }}</span
            >
            <span v-if="!municipioValido">
              - MUNICÍPIO: {{ reportFilter.municipio }}
            </span>
            <span v-if="!escolaNomeValido">
              - ESCOLA: {{ reportFilter.escolaNome }}</span
            >
          </div>
          <div class="text-h5 justify-center">
            <strong class="light-blue--text text--darken-4"
              >RESULTADO DA PESQUISA:
            </strong>
            <span>{{ quantidadeEscolas }} ESCOLA(S)</span>
          </div>
        </v-col>
      </vue-aos>
    </v-row>
    <v-divider class="dividerP mt-3"></v-divider>
    <v-row class="mt-4 justify-center align-content-center">
      <v-col cols="12" v-for="(un, index) in report" :key="index">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th colspan="2" class="text-left">
                  <h1 class="light-blue--text text--darken-2">
                    {{ un.nomeEscola }}
                  </h1>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="font-weight-bold">Unidade Executora:</span>
                  {{ un.nomeUnidadeExecutora }}
                </td>
                <td>
                  <span class="font-weight-bold">Código:</span>
                  {{ un.codigoMec }}
                </td>
              </tr>
              <tr>
                <td>
                  <span class="font-weight-bold">Endereço:</span>
                  {{ un.enderecoCompleto }}
                </td>
                <td>
                  <span class="font-weight-bold">Município:</span>
                  {{ un.municipio }}
                </td>
              </tr>
              <tr>
                <td>
                  <span class="font-weight-bold">Tipo da escola:</span>
                  {{ un.tipoEscola }}
                </td>
                <td>
                  <span class="font-weight-bold">Total de alunos:</span>
                  {{ un.escolaTotalAlunos }}
                </td>
              </tr>
              <tr>
                <td>
                  <span class="font-weight-bold">CNPJ Escola:</span>
                  {{ un.cnpjEscola }}
                </td>
                <td>
                  <span class="font-weight-bold">CNPJ Unidade Executora:</span>
                  {{ un.cnpjUnidadeExecutora }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-data-table
          :headers="grid.headers"
          :items="un.repasses"
          :loading="grid.loading"
          loading-text="Carregando... aguarde"
          locale="pt"
          class="elevation-1 mt-4"
        >
          <template v-slot:item.valorRepasse="{ item }">
            <span>{{ item.valorRepasse | toCurrency }}</span>
          </template>

          <template v-slot:item.custeio="{ item }">
            <span>{{ item.custeio | toCurrency }}</span>
          </template>

          <template v-slot:item.capital="{ item }">
            <span>{{ item.capital | toCurrency }}</span>
          </template>

          <template v-slot:item.ano="{ item }">
            <span>{{ item.ano }}</span>
          </template>
        </v-data-table>

        <v-divider v-if="quantidadeEscolas > 1" class="mt-3"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueAos from "vue-aos";
import { createNamespacedHelpers } from "vuex";

import {
  PRE_LOGIN_LAYOUT_NAMESPACE,
  REPORT,
  REPORT_FILTER,
} from "store/modules/pre-login-layout";

const { mapGetters: preLoginGetters } = createNamespacedHelpers(
  PRE_LOGIN_LAYOUT_NAMESPACE
);

export default {
  name: "LadingPageRelatorioRepasse",
  components: {
    VueAos,
  },
  data: () => ({
    grid: {
      loading: false,
      headers: [
        {
          text: "VALOR DO REPASSE",
          value: "valorRepasse",
          align: "start",
          sortable: false,
        },
        {
          text: "CUSTEIO",
          value: "custeio",
          align: "start",
          sortable: false,
        },
        {
          text: "CAPITAL",
          value: "capital",
          align: "center",
          sortable: false,
        },
        {
          text: "ANO DO REPASSE",
          value: "ano",
          align: "center",
          sortable: false,
        },
      ],
    },
  }),
  computed: {
    ...preLoginGetters([REPORT, REPORT_FILTER]),
    quantidadeEscolas() {
      return this.report.length;
    },
    escolaNomeValido() {
      return this.reportFilter.escolaNome === "";
    },
    municipioValido() {
      return this.reportFilter.municipio === "";
    },
    nomeGreValido() {
      return this.reportFilter.greNome === "";
    },
    nomeUnidadeExecutoraValido() {
      return this.reportFilter.unidadeExecutora.nome === "";
    },
  },
};
</script>

<style lang="scss" scoped>
.dividerP {
  border-width: 4px !important;
  border-color: black !important;
  height: 100%;
}
</style>
