<template>
  <div>
    <v-col>
      <v-autocomplete
        v-model="reportFilter.greNome"
        :loading="loading"
        :items="gres"
        item-text="nome"
        :search-input.sync="filtroGre"
        cache-items
        class="pa-2"
        flat
        hide-no-data
        hide-details
        label="SELECIONE A GERÊNCIA REGIONAL DE EDUCAÇÃO"
        solo-inverted
        rounded
        clearable
      ></v-autocomplete>
        <!-- @change="handleSearch" -->

      <v-autocomplete
        v-model="reportFilter.unidadeExecutora"
        :loading="loading"
        :items="diretorias"
        item-text="nome"
        return-object
        :search-input.sync="filtroUnidadeExecutora"
        cache-items
        class="pa-2"
        flat
        hide-no-data
        hide-details
        label="SELECIONE A UNIDADE EXECUTORA"
        solo-inverted
        rounded
        clearable
      ></v-autocomplete>
        <!-- @change="handleSearch" -->

      <v-autocomplete
        v-model="reportFilter.municipio"
        :loading="loading"
        :items="municipios"
        item-text="nome"
        :search-input.sync="filtroMunicipio"
        cache-items
        class="pa-2"
        flat
        hide-no-data
        hide-details
        label="SELECIONE O MUNICÍPIO"
        solo-inverted
        rounded
        clearable
      ></v-autocomplete>
        <!-- @change="handleSearch" -->

      <v-autocomplete
        v-model="reportFilter.escolaNome"
        :loading="loading"
        :items="escolas"
        item-text="nome"
        :search-input.sync="filtroNomeEscola"
        cache-items
        class="pa-2"
        flat
        hide-no-data
        hide-details
        label="SELECIONE A ESCOLA"
        solo-inverted
        rounded
        clearable
      ></v-autocomplete>
        <!-- @change="handleSearch" -->

      <v-autocomplete
        v-model="reportFilter.ano"
        :loading="loading"
        :items="anos"
        item-text="ano"
        :search-input.sync="filtroAno"
        cache-items
        class="pa-2"
        flat
        hide-no-data
        hide-details
        label="SELECIONE O ANO"
        solo-inverted
        rounded
        clearable
      ></v-autocomplete>
        <!-- @change="handleSearch" -->
    </v-col>
    <v-row class="pa-2" align="left" justify="space-around">
      <v-btn rounded color="success" @click="handleSearch">Pesquisar</v-btn>
      <v-btn rounded color="primary" @click="clearSearch"> Limpar </v-btn>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import notificationMixin from "mixins/notification.mixin";
import pageLoadingMixin from "mixins/page-loading.mixin";
import configurations from "~/commons/configurations";
import {
  PRE_LOGIN_LAYOUT_NAMESPACE,
  UPDATE_IS_GENERATING_REPORTS,
  UPDATE_REPORT_FILTER,
  UPDATE_REPORT,
  REPORT_FILTER,
  CLEAR_REPORT,
  IS_GENERATING_REPORTS,
} from "store/modules/pre-login-layout";

const {
  mapActions: preLoginActions,
  mapGetters: preLoginGetters,
} = createNamespacedHelpers(PRE_LOGIN_LAYOUT_NAMESPACE);

export default {
  mixins: [pageLoadingMixin, notificationMixin],
  props: {
    gres: {
      type: Array,
      require: true,
      default: () => [],
    },
    diretorias: {
      type: Array,
      required: true,
      default: () => [],
    },
    municipios: {
      type: Array,
      required: true,
      default: () => [],
    },
    escolas: {
      type: Array,
      required: true,
      default: () => [],
    },
    anos: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data: () => ({
    isFormValid: undefined,
    select: null,
    loading: false,
    filtroUnidadeExecutora: "",
    filtroMunicipio: "",
    filtroNomeEscola: "",
    filtroGre: "",
    filtroAno: "",
  }),
  methods: {
    ...preLoginActions([
      UPDATE_IS_GENERATING_REPORTS,
      UPDATE_REPORT,
      UPDATE_REPORT_FILTER,
      CLEAR_REPORT,
    ]),
    handleSearch() {
      this.selectedFiltros();
      // if (this.reportFilter.filtros.includes("filtroAno") && this.valueSearch) {
      //   this.notifyWarning("Selecione pelo menos mais um filtro");
      //   return;
      // }
      if (this.valueSearch) {
        this.notifyWarning("Selecione pelo menos um filtro");
        return;
      }

      this.generateReport();
    },
    generateReport() {
      this.loading = true;
      this.$api()
        .post(
          configurations.api.home.gerarRelatorioDadosRepasse,
          this.reportFilter
        )
        .then(({ data }) => {
          if (data && data.relatorio) {
            this[UPDATE_REPORT](data.relatorio);
            this[UPDATE_IS_GENERATING_REPORTS](true);
          } else {
            this.notifyWarning(
              "Não conseguimos gerar o relatório para o filtro informado."
            );
          }
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning(
              "Não conseguimos gerar o relatório para o filtro informado."
            );
          }
        });
    },
    clearSearch() {
      return this[CLEAR_REPORT]();
    },
    isEmpty(str) {
      return !str || str.length === 0;
    },
    selectedFiltros() {
      if (!this.isEmpty(this.reportFilter.ano))
        this.reportFilter.filtros.push("filtroAno");
      if (!this.isEmpty(this.reportFilter.greNome))
        this.reportFilter.filtros.push("filtroGreNome");
      if (!this.isEmpty(this.reportFilter.unidadeExecutora.nome))
        this.reportFilter.filtros.push("filtroUnidadeExecutora");
      if (!this.isEmpty(this.reportFilter.municipio))
        this.reportFilter.filtros.push("filtroMunicipio");
      if (!this.isEmpty(this.reportFilter.escolaNome))
        this.reportFilter.filtros.push("filtroNomeEscola");
    },
  },
  computed: {
    ...preLoginGetters([REPORT_FILTER, IS_GENERATING_REPORTS]),
    valueSearch() {
      return (
        this.isEmpty(this.reportFilter.unidadeExecutora.nome) &&
        this.isEmpty(this.reportFilter.municipio) &&
        this.isEmpty(this.reportFilter.escolaNome) &&
        this.isEmpty(this.reportFilter.greNome) &&
        this.isEmpty(this.reportFilter.ano)
      );
    }
  },
};
</script>
